<template>
  <div class="button-group mb-10" :class="[
      $props.type === 'group' ? 'button-container inline-flex content-stretch flex-wrap -mx-2' : '',
      $props.type === 'navigation' ? 'navigation-list' : ''
  ]">
    <Buttons v-for="(button, key) in $props.buttons" :key="key" :type="$props.type === 'navigation' ? 'navigation' : 'group'" :href="button.href" :content="button.content" :color="button.color" :icon="!!button.icon" :icon_type="button.icon" :icon_weight="button.icon_weight" />
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const Buttons = defineAsyncComponent(() => import("@/components/Buttons"));

export default {
  name: "ButtonGroup",
  props: ["buttons", "type"],
  components: {
    Buttons
  }
}
</script>